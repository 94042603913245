
import Vue from 'vue';

import { createNamespacedHelpers, mapMutations, mapState } from 'vuex';
import { SET_COMPANY, toggleSnack } from '@/plugins/vuex/mutationTypes';
import { IUserProfileBase } from '@/domains/user';

import AppAlertBar from '@/components/app/AppAlertBar.vue';
import HelpWidget from '@/components/HelpWidget.vue';

const companyHelpers = createNamespacedHelpers('company');

type menuBreakpoints = { [key: string]: boolean } | Record<any, never>;

export default Vue.extend({
  name: 'AppLayout',

  components: {
    AppAlertBar,
    HelpWidget,
  },

  inject: ['Names'],

  data: () => ({
    profile: {} as IUserProfileBase,
  }),

  computed: {
    withMenu(): menuBreakpoints {
      if (this.$vuetify.application.left) {
        return {
          'tt-main__xl_menu': this.$vuetify.breakpoint.xl,
          'tt-main__lg_menu': this.$vuetify.breakpoint.lg,
          'tt-main__md_menu': this.$vuetify.breakpoint.md,
          'tt-main__sm_menu': this.$vuetify.breakpoint.sm,
        };
      }
      return {};
    },
    ...mapState(['snackbar', 'loading']),
  },

  async created() {
    try {
      await Promise.all([
        this.fetchProfile(),
      ]);
    } catch (err) {
      // no-error
    }
  },

  methods: {
    ...mapMutations([toggleSnack]),
    ...companyHelpers.mapMutations([SET_COMPANY]),

    async fetchProfile() {
      if (this.$store.getters['user/hasUser']) {
        this.profile = {
          name: {
            first: this.$store.getters['user/userName'],
            last: this.$store.getters['user/userLastName'],
            middle: this.$store.getters['user/userMiddleName'],
          },
          email: this.$store.getters['user/userEmail'],
          phoneNumber: this.$store.getters['user/userPhoneNumber'],
        };
      } else {
        this.$di.snack.error(this.$t('errors.0.cantGetProfile'));
      }
    },
  },
});
