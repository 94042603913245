
import {
  computed, defineComponent, PropType, toRef, VNode, watch,
} from 'vue';
import { setupHelpdesk, setupUsedesk } from '@/plugins';
import { IUserProfileBase } from '@/domains/user/types';
import { useFeatureFlag } from '@/plugins/feature-flags';
import isMobile from '@/helpers/isMobile';

export default defineComponent({
  name: 'HelpWidget',

  props: {
    profile: {
      type: Object as PropType<IUserProfileBase>,
      required: true,
    },
  },

  setup(props) {
    const profile = toRef(props, 'profile');
    const isDocumentReady = computed(() => document.readyState === 'complete');
    const usedeskEnabled = useFeatureFlag('usedesk');
    const helpdeskEnabled = useFeatureFlag('helpdesk');

    function setupHelpWidgets() {
      if (isMobile()) {
        console.info('Help widget disabled for mobile devices');
        return;
      }
      setupUsedesk(profile.value, usedeskEnabled.value);
      setupHelpdesk({
        profile: profile.value,
        enabled: helpdeskEnabled.value,
      });
    }

    watch(profile, (newValue) => {
      if (Object.keys(newValue).length !== 0 && isDocumentReady.value) {
        setupHelpWidgets();
      }
    }, { immediate: true });
  },

  render(createElement): VNode {
    return createElement();
  },
});
