<template>
  <div class="app-alert-bar">
    <VFadeTransition
      group
      leave-absolute
      tag="div"
      class="d-flex flex-column justify-center align-end"
    >
      <TTAlert
        v-for="(message, idx) in messages"
        v-bind="message"
        :key="idx"
        :dismissible="!message.actions"
        :data-test="`tt-alert-${idx}`"
        class="mb-3"
        @close="removeMessageOnClick(message)"
      />
    </VFadeTransition>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapState, mapActions } from 'vuex';
import TTAlert from '@uikit/components/TTAlert/TTAlert';
import { REMOVE_NOTIFICATION_ACTION } from '@/plugins/vuex/actionTypes';
// @ts-ignore

export default Vue.extend({
  name: 'AppAlertBar',
  components: { TTAlert },
  computed: {
    ...mapState('notifications', {
      messages: (state) => state.messages,
    }),
  },
  methods: {
    ...mapActions('notifications', [REMOVE_NOTIFICATION_ACTION]),
    removeMessageOnClick(message) {
      this[REMOVE_NOTIFICATION_ACTION](message);
    },
  },
});
</script>

<style lang="scss" scoped>
.app-alert-bar {
  z-index: 999;
  position: fixed;
  right: 36px;
  top: 24px;
}
</style>
